<template>
  <div class="profile">
    <el-form ref="form" :rules="rules" :model="form" label-width="120px" label-position="left" style="width: 500px">
      <el-form-item label="Роль">
        <el-input :value="roleNames[user.role]" disabled />
      </el-form-item>

      <el-form-item label="логин" prop="login">
        <el-input v-model="form.login" />
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="Новый пароль">
        <el-input v-model="form.password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">OK</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { roleNames } from '@/components/utils.js'

export default {
  store: ['user'],

  data() {
    return {
      roleNames: roleNames,

      form: {
        login: '',
        password: ''
      },

      rules: {
        login: [{ required: true, message: 'Пожалуйчта введите логин', trigger: 'change' }]
      }
    }
  },

  beforeMount () {
    this.form.login = this.user.login
  },

  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios.post('profile', { login: this.form.login, password: this.form.password })
            .then(() => {
              this.$nextTick(function () {
                this.user.logout()
              })
            })
            .catch(err => {
              // console.log(err)
              console.log(err.response.data)
              if (err.response.data.error) {
                this.$notify.error({title: 'Error',  message: err.response.data.error})
              }
            })
          }
        })
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 40px - @header-height);
}
</style>
